/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { fromEnumAsString, generateUniqueDatabaseId, isNullOrUndefinedOrEmpty } from 'in-time-core';
import { logError, logInfo } from 'in-time-logger';
import { Renderer2 } from '@angular/core';
import { AnalyticsTracker } from './analytics-tracker';
import {
  AnalyticsTrackerType, CartEventArgs, ContentViewEventArgs, ExploreEventArgs, PageViewEventArgs,
  PromoCodeEventArgs, SearchEventArgs, SignUpEventArgs
} from './analytics.types';
import {
  GTM_ADD_TO_CART, GTM_COMPLETE_REGISTRATION, GTM_CONTENT_VIEW,
  GTM_EXPLORE, GTM_INITIATE_CHECKOUT, GTM_INITIATE_PURCHASE,
  GTM_PROMO_CODE_ENTERED, GTM_PURCHASE, GTM_PURCHASE_CANCELED,
  GTM_PURCHASE_FAILED, GTM_SEARCH, GTM_SIGN_UP,
} from './analytics.events';

interface TagManagerEntry { tagManagerId: string, elementId: string, dataLayerName: string }

function logEventToGtmTracker(dataLayerName: string, args: any): void {
  (window as any)[dataLayerName].push(args);
}

function grantUpdatedConsentToGtmTracker(dataLayerName: string, granted: boolean): void {
  function gtag(
    p0: 'consent',
    p1: 'update',
    p2: { ad_storage: string; ad_user_data: string; ad_personalization: string; analytics_storage: string; }
  ) {
    // eslint-disable-next-line prefer-rest-params
    (window as any)[dataLayerName].push(arguments);
  }

  const consent = granted ? 'granted' : 'denied';
  gtag('consent', 'update', {
    'ad_storage': consent,
    'ad_user_data': consent,
    'ad_personalization': consent,
    'analytics_storage': consent,
  });
}

function getGoogleTagManagerCode(tagManagerId: string, dataLayerName: string, elementId: string, hasConsent: boolean): string {
  const defaultConsent = hasConsent ? 'granted' : 'denied';
  const gtagName = `grantDefaultConsentTo${tagManagerId.substring(4)}`;

  return `
  window['${dataLayerName}'] = window['${dataLayerName}'] || [];
  function ${gtagName}(){ window['${dataLayerName}'].push(arguments); }

  ${gtagName}('consent', 'default', {
    'ad_storage': '${defaultConsent}',
    'ad_user_data': '${defaultConsent}',
    'ad_personalization': '${defaultConsent}',
    'analytics_storage': '${defaultConsent}',
  });

  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.id='${elementId}';j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','${dataLayerName}','${tagManagerId}');`;
}

function getGoogleTagManagerIframeCode(tagManagerId: string, elementId: string): string {
  return `<iframe src="https://www.googletagmanager.com/ns.html?id=${tagManagerId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
}

function createGoogleTagManager(
  _document: Document,
  renderer: Renderer2,
  tagManagerId: string,
  hasConsent: boolean,
): { elementId: string, dataLayerName: string } {
  const tagId = tagManagerId.substring(4);
  const elementId = `gtm-elem-${tagId}`;
  const dataLayerName = `dataLayer${tagId}`;

  const iframeNoScriptElement = renderer.createElement('noscript');
  renderer.setAttribute(iframeNoScriptElement, 'id', `iframe-${elementId}`);
  renderer.setProperty(iframeNoScriptElement, 'innerHTML', getGoogleTagManagerIframeCode(tagManagerId, elementId));
  renderer.appendChild(_document.body, iframeNoScriptElement);

  const loaderScriptElement = renderer.createElement('script');

  renderer.setAttribute(loaderScriptElement, 'id', `loader-${elementId}`);
  renderer.setAttribute(loaderScriptElement, 'type', 'text/javascript');
  renderer.setProperty(loaderScriptElement, 'innerHTML', getGoogleTagManagerCode(tagManagerId, dataLayerName, elementId, hasConsent));
  renderer.appendChild(_document.head, loaderScriptElement);

  return { elementId, dataLayerName };
}

function destroyGoogleTagManager(
  _document: Document,
  elementId: string,
): void {
  const iframe = _document.getElementById(`iframe-${elementId}`);
  if(iframe != null) {
    iframe.remove();
  }

  const loader = _document.getElementById(`loader-${elementId}`);
  if(loader != null) {
    loader.remove();
  }

  const tagManager = _document.getElementById(elementId);
  if(tagManager != null) {
    tagManager.remove();
  }
}

export class GtmTracker extends AnalyticsTracker {
  private readonly initializedTagManager: TagManagerEntry[] = [];
  private readonly customTagManagerStack: TagManagerEntry[] = [];
  private hasAcceptedCookies: boolean = false;
  private hasBeenInitialized: boolean = false;

  public readonly $typeId: AnalyticsTrackerType = 'gtm';

  static create(opts: {
    renderer: Renderer2,
    document: Document,
  }): GtmTracker {
    return new GtmTracker(opts.renderer, opts.document);
  }

  private constructor(
    private readonly renderer: Renderer2,
    private readonly _document: Document,
  ) { super(); }

  onInit(hasAcceptedCookies: boolean): void {
    this.hasAcceptedCookies = hasAcceptedCookies;
    this.hasBeenInitialized = true;
  }

  onDestroy(): void {
    // nothing to do here :)
  }

  onCookiesAccepted(): void {
    this.hasAcceptedCookies = true;
    this.trackConsent(this.hasAcceptedCookies);
  }

  onCookiesDeclined(): void {
    this.hasAcceptedCookies = false;
    this.trackConsent(this.hasAcceptedCookies);
  }

  activateCustomTagManager(opts: { context: string, customTagManagerId: string }): void {
    if(!this.hasBeenInitialized || isNullOrUndefinedOrEmpty(opts.customTagManagerId)) return;

    try {
      let customTagManager = this.getCustomTagManager(opts.customTagManagerId);
      if(customTagManager == null) {
        const { elementId, dataLayerName } = createGoogleTagManager(this._document, this.renderer, opts.customTagManagerId, this.hasAcceptedCookies);

        customTagManager = { tagManagerId: opts.customTagManagerId, elementId, dataLayerName };
        this.initializedTagManager.push(customTagManager);
      }

      this.customTagManagerStack.push(customTagManager);
      logInfo(`Custom Google Tag Manager has been activated for {${opts.context}}.`);
    }
    catch(error) {
      logError(`Failed to activate custom Google Tag Manager for {${opts.context}}.`, error);
    }
  }

  deactivateCustomTagManager(opts: { context: string, customTagManagerId: string }): void {
    for(let i = this.customTagManagerStack.length - 1; i >= 0; i--) {
      if(this.customTagManagerStack[i].tagManagerId === opts.customTagManagerId) {
        this.destroyTagManager(opts.customTagManagerId);
        this.customTagManagerStack.splice(i, 1);
        logInfo(`Custom Google Tag Manager has been deactivated for {${opts.context}}.`);
        return;
      }
    }
  }

  trackPromoCode(args: PromoCodeEventArgs): void {
    this.track(GTM_PROMO_CODE_ENTERED, {
      promo_code: args.promoCode,
    });
  }

  trackPageView(args: PageViewEventArgs): void {
    // nothing to do here :)
  }

  trackSignUp(args: SignUpEventArgs): void {
    this.track(GTM_SIGN_UP, {
      authProviders: args.authProviders,
      marketingConsent: args.marketingConsent ?? 'N/A',
    });
  }

  trackSearch(args: SearchEventArgs): void {
    this.track(GTM_SEARCH, {
      searchString: args.query,
      contentCategory: args.category,
      ...args.queryParams,
    });
  }

  trackExplore(args: ExploreEventArgs): void {
    this.track(GTM_EXPLORE, {
      route: args.id,
      via: args.via,
      eventName: args.eventName,
      eventId: args.eventId,
      businessId: args.businessId,
      organizationId: args.organizationId,
    });
  }

  trackContentView(args: ContentViewEventArgs): void {
    this.track(GTM_CONTENT_VIEW, {
      category: args.category,
      title: args.title,
      contentId: args.contentId,
      businessId: args.businessId,
      organizationId: args.organizationId,
      ...args.queryParams,
    });
  }

  trackAddToCart(args: CartEventArgs): void {
    this.track(GTM_ADD_TO_CART, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      eventName: args.eventName,
      eventId: args.eventId,
      businessId: args.businessId,
      organizationId: args.organizationId,
      items: args.cart.items.map((item) => ({
        itemId: item.uniqueId,
        itemName: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.unitPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });
  }

  trackInitiateCheckout(args: CartEventArgs): void {
    this.track(GTM_INITIATE_CHECKOUT, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      eventName: args.eventName,
      eventId: args.eventId,
      businessId: args.businessId,
      organizationId: args.organizationId,
      items: args.cart.items.map((item) => ({
        itemId: item.uniqueId,
        itemName: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.totalPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });
  }

  trackCompleteRegistration(args: CartEventArgs): void {
    this.track(GTM_COMPLETE_REGISTRATION, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      eventName: args.eventName,
      eventId: args.eventId,
      businessId: args.businessId,
      organizationId: args.organizationId,
      items: args.cart.items.map((item) => ({
        itemId: item.uniqueId,
        itemName: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.totalPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      })),
    });
  }

  trackInitiatePurchase(args: CartEventArgs): void {
    this.track(GTM_INITIATE_PURCHASE, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      eventName: args.eventName,
      eventId: args.eventId,
      businessId: args.businessId,
      organizationId: args.organizationId,
      items: args.cart.items.map((item) => ({
        itemId: item.uniqueId,
        itemName: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.totalPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });
  }

  trackPurchaseComplete(args: CartEventArgs): void {
    this.track(GTM_PURCHASE, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      eventName: args.eventName,
      eventId: args.eventId,
      businessId: args.businessId,
      organizationId: args.organizationId,
      items: args.cart.items.map((item) => ({
        itemId: item.uniqueId,
        itemName: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.unitPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });
  }

  trackPurchaseFailed(args: CartEventArgs): void {
    this.track(GTM_PURCHASE_FAILED, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      eventName: args.eventName,
      eventId: args.eventId,
      businessId: args.businessId,
      organizationId: args.organizationId,
      items: args.cart.items.map((item) => ({
        itemId: item.uniqueId,
        itemName: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.unitPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });
  }

  trackPurchaseCanceled(args: CartEventArgs): void {
    this.track(GTM_PURCHASE_CANCELED, {
      currency: fromEnumAsString(args.cartPrice.currency),
      value: args.cartPrice.asDouble,
      eventName: args.eventName,
      eventId: args.eventId,
      businessId: args.businessId,
      organizationId: args.organizationId,
      items: args.cart.items.map((item) => ({
        itemId: item.uniqueId,
        itemName: item.name.getAny(args.language) ?? item.uniqueId,
        currency: fromEnumAsString(item.unitPrice.currency),
        price: item.unitPrice.asDouble,
        quantity: item.quantity,
      }))
    });
  }

  private getCustomTagManager(tagManagerId: string): TagManagerEntry | null {
    for(let i = 0; i < this.initializedTagManager.length; i++) {
      if(this.initializedTagManager[i].tagManagerId == tagManagerId) {
        return this.initializedTagManager[i];
      }
    }

    return null;
  }

  private destroyTagManager(tagManagerId: string): boolean {
    for(let i = this.initializedTagManager.length - 1; i >= 0; i--) {
      if(this.initializedTagManager[i].tagManagerId == tagManagerId) {
        destroyGoogleTagManager(this._document, this.initializedTagManager[i].elementId);
        this.initializedTagManager.splice(i, 1);
        return true;
      }
    }

    return false;
  }

  private track(eventId: string, parameters: object): void {
    if(!this.hasBeenInitialized) return;
    if(!this.hasAcceptedCookies) return;

    try {
      if(this.customTagManagerStack.length > 0) {
        const tagManager = this.customTagManagerStack[this.customTagManagerStack.length - 1];
        logEventToGtmTracker(tagManager.dataLayerName, { 'event': eventId, ...parameters });
        logInfo(`Tracking GTM event {${eventId}} with params: ${JSON.stringify(parameters, null, 2)}`);
      }
    }
    catch(error) {
      logError(`Failed to track GTM event {${eventId}} with params: ${JSON.stringify(parameters, null, 2)}`, error);
    }
  }

  private trackConsent(hasConsent: boolean): void {
    if(!this.hasBeenInitialized) return;

    try {
      if(hasConsent) {
        logInfo('Granting consent for GTM.');
      }
      else {
        logInfo('Denying consent for GTM.');
      }

      for(const tagManager of this.customTagManagerStack) {
        grantUpdatedConsentToGtmTracker(tagManager.dataLayerName, hasConsent);
      }
    }
    catch(error) {
      logError('Failed to track GTM consent.', error);
    }
  }
}