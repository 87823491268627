/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import { Injectable, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { CookieOptions, CookieService as NgxCookieService } from 'ngx-cookie-service';
import { TrueTime } from 'in-time-core';
import dayjs from 'dayjs';
import { REQUEST } from '../../express.tokens';
import { RequestToken } from '../core/utils/type-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { COOKIE_COOKIES_ACCEPTED, COOKIE_COOKIES_DECLINED_RECENTLY } from '../core/utils/cookie-keys';

const kCookiesDeclinedRecentlyExpirationDuration = dayjs.duration(1, 'week');
const kDefaultCookieExpirationDuration = dayjs.duration(1, 'year');
const kDefaultCookiePath = '/';

interface CookieDeleteOptions {
  path: string;
  domain: string;
}

function getDefaultCookieExpirationDate(): Date {
  return TrueTime.now().add(kDefaultCookieExpirationDuration).toDate();
}

@Injectable({
  providedIn: 'root'
})
export class CookieService implements OnDestroy {
  private readonly request: RequestToken | null = inject(REQUEST, { optional: true });
  private readonly ngxCookieService = inject(NgxCookieService, { optional: true });
  private readonly platformId = inject(PLATFORM_ID);

  private readonly shouldRequestCookieConsentSnapshots: BehaviorSubject<boolean>;

  get shouldRequestCookieConsent$(): Observable<boolean> {
    return this.shouldRequestCookieConsentSnapshots;
  }

  get shouldRequestCookieConsent(): boolean {
    return this.shouldRequestCookieConsentSnapshots.value;
  }

  constructor() {
    this.shouldRequestCookieConsentSnapshots = new BehaviorSubject<boolean>(false);
  }

  load(): void {
    const hasAcceptedCookies = this.hasAcceptedCookies();
    const declinedRecently = this.hasDeclinedRecently();
    const shouldShowCookiePanel = !hasAcceptedCookies && !declinedRecently;

    this.shouldRequestCookieConsentSnapshots.next(shouldShowCookiePanel);
  }

  ngOnDestroy(): void {
    this.shouldRequestCookieConsentSnapshots.complete();
  }

  private hasDeclinedRecently(): boolean {
    return this.get(COOKIE_COOKIES_DECLINED_RECENTLY) ? true : false;
  }

  hasAcceptedCookies(): boolean {
    return this.get(COOKIE_COOKIES_ACCEPTED) ? true : false;
  }

  accept(): void {
    this.set(COOKIE_COOKIES_ACCEPTED, '1');
    this.shouldRequestCookieConsentSnapshots.next(false);
  }

  decline(): void {
    this.delete(COOKIE_COOKIES_ACCEPTED);

    const expirationDate = TrueTime
      .now()
      .add(kCookiesDeclinedRecentlyExpirationDuration)
      .toDate();

    this.set(COOKIE_COOKIES_DECLINED_RECENTLY, '1', {
      expires: expirationDate,
    });

    this.shouldRequestCookieConsentSnapshots.next(false);
  }

  check(name: string): boolean {
    if(isPlatformServer(this.platformId) && this.request != null) {
      return this.request.cookies[name] !== undefined;
    }
    if(this.ngxCookieService != null) {
      return this.ngxCookieService.check(name);
    }

    return false;
  }

  get(name: string): string | null {
    if(isPlatformServer(this.platformId) && this.request != null) {
      return this.request.cookies[name] ?? null;
    }
    if(this.ngxCookieService != null) {
      return this.ngxCookieService.get(name) ?? null;
    }

    return null;
  }

  getAll(): { [key: string]: string } {
    if(isPlatformServer(this.platformId) && this.request != null) {
      return this.request.cookies;
    }
    if(this.ngxCookieService != null) {
      return this.ngxCookieService.getAll();
    }

    return {};
  }

  set(
    name: string,
    value: string,
    options?: Partial<CookieOptions>
  ) {
    if(isPlatformServer(this.platformId) && this.request != null) {
      this.request.cookies[name] = value;
    }
    if(this.ngxCookieService != null) {
      this.ngxCookieService.set(name, value, {
        expires: options?.expires ?? getDefaultCookieExpirationDate(),
        path: options?.path ?? kDefaultCookiePath,
        domain: options?.domain,
        secure: options?.secure,
        sameSite: options?.sameSite,
      });
    }
  }

  delete(
    name: string,
    options?: Partial<CookieDeleteOptions>,
  ) {
    if(isPlatformServer(this.platformId) && this.request != null) {
      this.request.cookies[name] = undefined;
    }
    if(this.ngxCookieService != null) {
      this.ngxCookieService.delete(name, options?.path ?? '/', options?.domain);
    }
  }
}